import React, { useEffect, useState, useRef } from "react";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import { Card, CardBody } from "./../../../components/card/card.jsx";
import Select from "react-select";

import Chart from "chart.js/auto";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.min.css";
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";

const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis.min.js");
require("datatables.net-buttons/js/buttons.flash.min.js");
require("datatables.net-buttons/js/buttons.html5.min.js");
require("datatables.net-buttons/js/buttons.print.min.js");
require("datatables.net-buttons-bs5");
require("datatables.net-responsive");
require("datatables.net-responsive-bs5");
require("datatables.net-fixedcolumns");
require("datatables.net-fixedcolumns-bs5");

const BlognewsRecipeExpand = () => {
  const BlognewsRecipeExpandChartRef = useRef(null);
  const BlognewsRecipetableRef = useRef(null);
  const BlognewsRecipemodalTableRef = useRef(null);

  const [BlognewsRecipeModalData, setBlognewsRecipeModalData] = useState([]);
  const [show, setShow] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);

  const [datelabel, setDatelabel] = useState();
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);

  const [data, setData] = useState([]);
  const [labels, setLabelsData] = useState([]);
  const [values, setValuesData] = useState([]);

  var themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var themeColorRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme-rgb")
    .trim();
  var bodyBg = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-bg")
    .trim();

  const currentTime = Math.floor(new Date().getTime() / 1000);

  const setupChart = (
    canvasRef,
    labels,
    data,
    themeColor,
    themeColorRgb,
    bodyBg
  ) => {
    if (!canvasRef.current) return;
    return new Chart(canvasRef.current, {
      type: "line",
      data: {
        labels,
        datasets: [
          {
            label: "Users Over Time",
            data,
            borderColor: themeColor,
            borderWidth: 1.5,
            pointBackgroundColor: bodyBg,
            pointBorderWidth: 1.5,
            pointRadius: 4,
            pointHoverBackgroundColor: themeColor,
            pointHoverBorderColor: bodyBg,
            pointHoverRadius: 7,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    });
  };



  




const handleBlognewsDetails = async (rowData) => {


  const selectedPostId = selectedOption?.value;


  const detailsMatch = rowData.Details.match(/\(([^,]+),([^,]+),([^)]+)\)/);
  if (!detailsMatch) {
    console.error("Invalid Details format in RowData:", rowData.Details);
    return;
  }

  const [table, startTime, endTime] = detailsMatch.slice(1);
  console.log("Parsed Details:", table, startTime, endTime);

  setDatelabel(rowData.label);

  try {
    const apiUrl =
      !selectedPostId || selectedPostId === "default"
        ? `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardAdditionalApiData?table=blog_statistics&startTime=${startTime}&endTime=${endTime}`
        : `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardlistDetailApi?table=blog_statistics&startTime=${startTime}&endTime=${endTime}&postId=${selectedPostId}`;

    console.log("API URL:", apiUrl);

    const response = await fetch(apiUrl);
    const result = await response.json();

    console.log("Details View Result:", result);
    setBlognewsRecipeModalData([...result].reverse());

    setShow(true);
  } catch (error) {
    console.error("Error fetching modal data:", error);
  }
};


  

  const handleDropdownChange = async (option) => {
    setSelectedOption(option);
  
    try {
      const apiUrl = option?.value === "default" || !option
        ? `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=0&endTime=${currentTime}&table=blog_statistics`
        : `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardlistChartApi?table=blog_statistics&startTime=0&endTime=${currentTime}&postId=${option.value}`;
  
        
        const response = await fetch(apiUrl);
        const result = await response.json();
        console.log("Fetching data for dropdown change:", apiUrl);
        
      const chartLabels = result.dateswise.map((item) => item.label);
      const chartValues = result.dateswise.map((item) => item.value);
      console.log(result)
      setData(result);
      setLabelsData(chartLabels);
      setValuesData(chartValues);
    } catch (error) {
      console.error("Error fetching new data:", error);
    }
  };
  
  const fetchDropdownOptions = async () => {
    try {
      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardlistApi?table=blog&startTime=0&endTime=${currentTime}`
      );
      const options = await response.json();
      const formattedOptions = [
        { value: "default", label: "Select Option" }, // Static option
        ...options.map((option) => ({
          value: option.id,
          label: option.title,
        })),
      ];
      setDropdownOptions(formattedOptions);
    } catch (error) {
      console.error("Error fetching dropdown options:", error);
    }
  };


  const fetchData = async () => {
    try {


      const response = await fetch(
        `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=0&endTime=${currentTime}&table=blog_statistics`
      );

      const result = await response.json();

      const chartLabels = result.dateswise.map((item) => item.label);
      const chartValues = result.dateswise.map((item) => item.value);
      setData(result);
      setLabelsData(chartLabels);
      setValuesData(chartValues);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

    useEffect(() => {
    const chartInstance = setupChart(
      BlognewsRecipeExpandChartRef,
      labels,
      values,
      themeColor,
      themeColorRgb,
      bodyBg
    );
    return () => chartInstance && chartInstance.destroy();
  }, [labels, values, themeColor, themeColorRgb, bodyBg]);

  useEffect(() => {
    // $(BlognewsRecipetableRef.current).DataTable().destroy();
    if (BlognewsRecipetableRef.current) {
      $(BlognewsRecipetableRef.current).empty();
      // if ($.fn.DataTable.isDataTable(BlognewsRecipetableRef.current)) {
      //   // $(BlognewsRecipetableRef.current).DataTable().destroy();
      // }
  
      $(BlognewsRecipetableRef.current).DataTable({
        data: [...(data?.dateswise || [])].reverse(),
        columns: [
          {
            title: "Serial Number",
            data: null,
            render: (data, type, row, meta) => meta.row + 1,
          },
          { title: "Date", data: "label" },
          { title: "Counts", data: "value" },
          {
            title: "Details",
            data: null,
            render: (data, type, row) => {
              return `<button class='btn btn-primary btn-sm view-details' data-row='${JSON.stringify(
                row
              )}'>View</button>`;
            },
          },
        ],
        destroy: true,
        responsive: true,
      });
  
      $(BlognewsRecipetableRef.current)
        .off("click", ".view-details")
        .on("click", ".view-details", function () {
          const rowData = $(this).data("row");
          // console.log("New Row Data", rowData);
          handleBlognewsDetails(rowData);
        });
    }
  }, [data]);

  useEffect(() => {
    fetchData();
    fetchDropdownOptions();
  }, []);

  useEffect(() => {
    if (BlognewsRecipeModalData.length > 0) {
      // Define the columns based on whether a specific post is selected

      if ($.fn.DataTable.isDataTable(BlognewsRecipemodalTableRef.current)) {
        $(BlognewsRecipemodalTableRef.current).DataTable().destroy();
      }

      const isDefaultData =
        selectedOption?.value === "default" || selectedOption == undefined; // Adjust based on your default logic

      const columns = isDefaultData
        ? [
            { title: "ID", data: "id" },
            { title: "News", data: "news.title" },
            { title: "View Count", data: "news.viewcount" },
            { title: "Created", data: "created" },
            { title: "Updated", data: "updated" },
          ]
        : [
            { title: "ID", data: "id" },
            { title: "News", data: "news" },
            { title: "News Viewed Total", data: "news_viewed_total" },
            { title: "View By", data: "view_by" },
            { title: "View Time", data: "view_time" },
            { title: "Created", data: "create" },
            { title: "Updated", data: "update" },
          ];

      // Initialize DataTable
      $(BlognewsRecipemodalTableRef.current).DataTable({
        data: BlognewsRecipeModalData,
        columns,
        destroy: true, // Ensure proper reinitialization
        responsive: true,
      });
    }
  }, [BlognewsRecipeModalData, selectedOption]);

  return (
    <>
      <div>
        <h1>Blog News</h1>
      </div>

      <div className="col-md-12 mb-4">
        <label htmlFor="blog">Select Post</label>
        <Select
          options={dropdownOptions} // Dropdown options
          value={selectedOption} // Current selected value
          onChange={handleDropdownChange} // Update selected value
          classNamePrefix="react-select"
        />
      </div>

      <div className="accordion mb-2" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
            >
              Chart Over Time
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <canvas
                ref={BlognewsRecipeExpandChartRef}
                className="w-100"
                height={100}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
            >
              Details Over Time
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body">
              <table
                ref={BlognewsRecipetableRef}
                className="table table-striped table-responsive w-100"
                height={100}
              ></table>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>BARSYS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <CardBody>
              <h2>Blog News - {datelabel}</h2>
              <table
                ref={BlognewsRecipemodalTableRef}
                className="table table-striped table-responsive w-100"
                height={100}
              ></table>
            </CardBody>
          </Card>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BlognewsRecipeExpand;
