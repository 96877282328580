import React, { useEffect, useState, useRef } from "react";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import { Card, CardBody } from "./../../../components/card/card.jsx";

import Chart from "chart.js/auto";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";


import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import 'datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css';
import 'datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.min.css';
import 'datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css';

const $ = require('jquery');
$.DataTable = require('datatables.net');
require('datatables.net-bs5');
require('datatables.net-buttons');
require('datatables.net-buttons/js/buttons.colVis.min.js');
require('datatables.net-buttons/js/buttons.flash.min.js');
require('datatables.net-buttons/js/buttons.html5.min.js');
require('datatables.net-buttons/js/buttons.print.min.js');
require('datatables.net-buttons-bs5');
require('datatables.net-responsive');
require('datatables.net-responsive-bs5');
require('datatables.net-fixedcolumns');
require('datatables.net-fixedcolumns-bs5');


const BrandcategoryRecipeExpand = React.memo(({ data, labels, values, themeColor, themeColorRgb, bodyBg }) => {
    const BrandcategoryRecipeExpandChartRef = useRef(null);
    const BrandcategoryRecipetableRef = useRef(null);
    const BrandcategoryRecipemodalTableRef = useRef(null);

    const [BrandcategoryRecipeModalData, setBrandcategoryRecipeModalData] = useState([]);
    const [show, setShow] = useState(false);
    const [fullscreen, setFullscreen] = useState(true);

    const [datelabel, setDatelabel] = useState()

    const setupChart = (canvasRef, labels, data, themeColor, themeColorRgb, bodyBg) => {
        if (!canvasRef.current) return;
        return new Chart(canvasRef.current, {
            type: "line",
            data: {
                labels,
                datasets: [
                    {
                        label: "Users Over Time",
                        data,
                        borderColor: themeColor,
                        borderWidth: 1.5,
                        pointBackgroundColor: bodyBg,
                        pointBorderWidth: 1.5,
                        pointRadius: 4,
                        pointHoverBackgroundColor: themeColor,
                        pointHoverBorderColor: bodyBg,
                        pointHoverRadius: 7,
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
            },
        });
    };

    useEffect(() => {
        const chartInstance = setupChart(
            BrandcategoryRecipeExpandChartRef,
            labels,
            values,
            themeColor,
            themeColorRgb,
            bodyBg
        );
        return () => chartInstance && chartInstance.destroy();
    }, [labels, values, themeColor, themeColorRgb, bodyBg]);

    useEffect(() => {
        if (BrandcategoryRecipetableRef.current) {
            $(BrandcategoryRecipetableRef.current).DataTable({
                data: [...(data?.dateswise || [])].reverse(),
                columns: [
                    {
                        title: "Serial Number",
                        data: null,
                        render: (data, type, row, meta) => meta.row + 1,
                    },
                    { title: "Date", data: "label" },
                    { title: "Counts", data: "value" },
                    {
                        title: "Details",
                        data: null,
                        render: (data, type, row) => {
                            return `<button class='btn btn-primary btn-sm view-details' data-row='${JSON.stringify(
                                row
                            )}'>View</button>`;
                        },
                    },
                ],
                destroy: true,
                responsive: true,
            });

            $(BrandcategoryRecipetableRef.current).on("click", ".view-details", function () {
                const rowData = $(this).data("row");
                handleBrandcategoryDetails(rowData);
            });
        }
    }, [data]);

    const handleBrandcategoryDetails = async (rowData) => {
        const [table, startTime, endTime] = rowData.Details.match(
            /\(([^,]+),([^,]+),([^)]+)\)/
        ).slice(1);

        setDatelabel(rowData.label)

        try {
            const response = await fetch(
                `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardAdditionalApiData?table=${table}&startTime=${startTime}&endTime=${endTime}`
            );
            const result = await response.json();
            // const reversedResult = [...result].reverse();
            // console.log([...result].reverse());
            setBrandcategoryRecipeModalData([...result].reverse());
            
            setShow(true);
        } catch (error) {
            console.error("Error fetching modal data:", error);
        }
    };

    useEffect(() => {
        if (BrandcategoryRecipeModalData.length > 0) {
            $(BrandcategoryRecipemodalTableRef.current).DataTable({
                data: BrandcategoryRecipeModalData,
                columns: [
                    { title: "ID", data: "id" },
                    { title: "Brand Category Name", data: "brandCategoryName" },
                    { title: "Created", data: "created" },
                    { title: "Updated", data: "updated" },
                ],
                destroy: true,
                responsive: true,
            });
        }
    }, [BrandcategoryRecipeModalData]);

    return (
        <>
        <div>
            <h1>Brand Category</h1>
        </div>
            <div className="accordion mb-2" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                        >
                            Chart Over Time
                        </button>
                    </h2>
                    <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <canvas ref={BrandcategoryRecipeExpandChartRef} className="w-100" height="450" />
                        </div>
                    </div>
                </div>
            </div>

            <div className="accordion" id="accordionExample">
                <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                        <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                        >
                            Details Over Time
                        </button>
                    </h2>
                    <div
                        id="collapseTwo"
                        className="accordion-collapse collapse show"
                        data-bs-parent="#accordionExample"
                    >
                        <div className="accordion-body">
                            <table ref={BrandcategoryRecipetableRef} className="table table-striped table-responsive w-100"></table>
                        </div>
                    </div>
                </div>
            </div>

            <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>BARSYS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card>
                        <CardBody>
                        <h2>Brandcategory - {datelabel}</h2> 
                            <table ref={BrandcategoryRecipemodalTableRef} className="table table-striped table-responsive w-100"></table>
                        </CardBody>
                    </Card>
                </Modal.Body>
            </Modal>
        </>
    );
});

export default BrandcategoryRecipeExpand;