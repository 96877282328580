import React, { useEffect, useState, useRef } from "react";
import "jsvectormap/dist/maps/world.js";
import "jsvectormap/dist/css/jsvectormap.min.css";
import { Card, CardBody } from "./../../components/card/card.jsx";

import Chart from "chart.js/auto";

import "bootstrap-daterangepicker/daterangepicker.css";

import "react-datepicker/dist/react-datepicker.css";

import DateTime from "react-datetime";
import "react-datetime/css/react-datetime.css";

import UserExpand from "./expand/userexpand.jsx";
import CuratedRecipeExpand from "./expand/curatedrecipeexpand.jsx";
import CustomRecipeExpand from "./expand/customrecipeexpand.jsx";
import MakeRecipeExpand from "./expand/makerecipeexpand.jsx";
import LikeRecipeExpand from "./expand/likerecipeexpand.jsx";
import ViewRecipeExpand from "./expand/viewrecipeexpand.jsx";
import BrandRecipeExpand from "./expand/brandrecipeexpand.jsx";
import BlogRecipeExpand from "./expand/blogrecipeexpand.jsx";
import BrandcategoryRecipeExpand from "./expand/brandcategoryexpand.jsx";
import ScheduleRecipeExpand from "./expand/schedulerecipeexpand.jsx";
import BlognewsRecipeExpand from "./expand/blognewsrecipeexpand.jsx";
import NotificationRecipeExpand from "./expand/notificationrecipeexpand.jsx";

import DatePicker from "react-datepicker";

import { ModalComponent } from "../expand/expandmodal.jsx";

import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-fixedcolumns-bs5/css/fixedColumns.bootstrap5.min.css";
import "datatables.net-buttons-bs5/css/buttons.bootstrap5.min.css";

const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis.min.js");
require("datatables.net-buttons/js/buttons.flash.min.js");
require("datatables.net-buttons/js/buttons.html5.min.js");
require("datatables.net-buttons/js/buttons.print.min.js");
require("datatables.net-buttons-bs5");
require("datatables.net-responsive");
require("datatables.net-responsive-bs5");
require("datatables.net-fixedcolumns");
require("datatables.net-fixedcolumns-bs5");

const Dashboardadmindata = () => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  // Search Trigger
  const [searchTriggered, setSearchTriggered] = useState(false);

  //User
  const [data, setData] = useState(null);
  const [labels, setLabels] = useState([]);
  const [values, setValues] = useState([]);

  //Curated Recipe
  const [curatedrecipedata, setCuratedRecipeData] = useState(null);
  const [curatedrecipelabels, setcuratedrecipeLabels] = useState([]);
  const [curatedrecipevalues, setcuratedrecipeValues] = useState([]);

  //Custom Recipe
  const [customrecipedata, setCustomRecipeData] = useState(null);
  const [customrecipelabels, setcustomrecipeLabels] = useState([]);
  const [customrecipevalues, setcustomrecipeValues] = useState([]);

  //Make Recipe
  const [makerecipedata, setMakeRecipeData] = useState(null);
  const [makerecipelabels, setmakerecipeLabels] = useState([]);
  const [makerecipevalues, setmakerecipeValues] = useState([]);

  //Like Recipe
  const [likerecipedata, setLikeRecipeData] = useState(null);
  const [likerecipelabels, setlikerecipeLabels] = useState([]);
  const [likerecipevalues, setlikerecipeValues] = useState([]);

  //View Recipe
  const [viewrecipedata, setViewRecipeData] = useState(null);
  const [viewrecipelabels, setviewrecipeLabels] = useState([]);
  const [viewrecipevalues, setviewrecipeValues] = useState([]);

  //Brand Recipe
  const [brandrecipedata, setBrandRecipeData] = useState(null);
  const [brandrecipelabels, setbrandrecipeLabels] = useState([]);
  const [brandrecipevalues, setbrandrecipeValues] = useState([]);

  //Blog Recipe
  const [blogrecipedata, setBlogRecipeData] = useState(null);
  const [blogrecipelabels, setblogrecipeLabels] = useState([]);
  const [blogrecipevalues, setblogrecipeValues] = useState([]);

  //Brand Category Recipe
  const [brandcategoryrecipedata, setBrandcategoryRecipeData] = useState(null);
  const [brandcategoryrecipelabels, setbrandcategoryrecipeLabels] = useState(
    []
  );
  const [brandcategoryrecipevalues, setbrandcategoryrecipeValues] = useState(
    []
  );


  // Schedule Notification
  const [schedulenotificationrecipedata, setSchedulenotificationRecipeData] = useState(null);
  const [schedulenotificationrecipelabels, setschedulenotificationrecipeLabels] = useState([]);
  const [schedulenotificationrecipevalues, setschedulenotificationrecipeValues] = useState([]);

    // Schedule Notification
    const [blogstatisticsrecipedata, setBlogstatisticsRecipeData] = useState(null);
    const [blogstatisticsrecipelabels, setblogstatisticsrecipeLabels] = useState([]);
    const [blogstatisticsrecipevalues, setblogstatisticsrecipeValues] = useState([]);

        //  Notification Statistics
        const [notificationrecipedata, setNotificationRecipeData] = useState(null);
        const [notificationrecipelabels, setnotificationrecipeLabels] = useState([]);
        const [notificationrecipevalues, setnotificationrecipeValues] = useState([]);

  // color & font variable
  var gray300Color = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-300")
    .trim();
  var gray300RgbColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-300-rgb")
    .trim();
  var indigoColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-indigo")
    .trim();
  var bodyColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-color")
    .trim();
  var bodyBg = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-bg")
    .trim();
  var borderColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-border-color")
    .trim();
  var bodyFontFamily = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-family")
    .trim();
  var bodyFontWeight = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-weight")
    .trim();
  var inverse = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse")
    .trim();
  var themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();

  // chart global options
  Chart.defaults.font.family = bodyFontFamily;
  Chart.defaults.font.size = 12;
  Chart.defaults.color = bodyColor;
  Chart.defaults.borderColor = borderColor;
  Chart.defaults.plugins.legend.display = false;
  Chart.defaults.plugins.tooltip.padding = {
    left: 8,
    right: 12,
    top: 8,
    bottom: 8,
  };
  Chart.defaults.plugins.tooltip.cornerRadius = 8;
  Chart.defaults.plugins.tooltip.titleMarginBottom = 6;
  Chart.defaults.plugins.tooltip.color = bodyBg;
  Chart.defaults.plugins.tooltip.multiKeyBackground = inverse;
  Chart.defaults.plugins.tooltip.backgroundColor = "black";
  Chart.defaults.plugins.tooltip.titleFont.family = bodyFontFamily;
  Chart.defaults.plugins.tooltip.titleFont.weight = bodyFontWeight;
  Chart.defaults.plugins.tooltip.footerFont.family = bodyFontFamily;
  Chart.defaults.plugins.tooltip.displayColors = true;
  Chart.defaults.plugins.tooltip.boxPadding = 6;
  Chart.defaults.scale.grid.color = borderColor;
  Chart.defaults.scale.beginAtZero = true;
  //  Chart.defaults.maintainAspectRatio = false;

  var themeFont = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-family")
    .trim();
  var themeFontWeight = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-font-weight")
    .trim();
  var gray500 = getComputedStyle(document.body)
    .getPropertyValue("--bs-gray-500")
    .trim();
  var orange = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var inverse = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse")
    .trim();
  var inverseRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-inverse-rgb")
    .trim();
  var borderColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-border-color")
    .trim();
  var indigo = getComputedStyle(document.body)
    .getPropertyValue("--bs-indigo")
    .trim();

  const getTimestamps = () => ({
    startTime: fromDate ? Math.floor(fromDate.getTime() / 1000) : 0,
    endTime: toDate
      ? Math.floor(toDate.getTime() / 1000)
      : Math.floor(new Date().getTime() / 1000),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "user"; // Table parameter
        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );

        // console.log(startTime, endTime);

        const result = await response.json();
        // console.log(result);
        setData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setLabels(chartLabels);
        setValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "recipe"; // Table parameter

        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );

        const result = await response.json();
        setCuratedRecipeData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setcuratedrecipeLabels(chartLabels);
        setcuratedrecipeValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "custom_recipe"; // Table parameter

        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );

        const result = await response.json();
        setCustomRecipeData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setcustomrecipeLabels(chartLabels);
        setcustomrecipeValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "make_recipe"; // Table parameter

        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );

        const result = await response.json();
        setMakeRecipeData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setmakerecipeLabels(chartLabels);
        setmakerecipeValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "like_recipe"; // Table parameter

        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );

        const result = await response.json();
        setLikeRecipeData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setlikerecipeLabels(chartLabels);
        setlikerecipeValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "click_recipe"; // Table parameter

        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );

        const result = await response.json();
        setViewRecipeData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setviewrecipeLabels(chartLabels);
        setviewrecipeValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "brand"; // Table parameter

        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );

        const result = await response.json();
        setBrandRecipeData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setbrandrecipeLabels(chartLabels);
        setbrandrecipeValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "blog"; // Table parameter

        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );

        // console.log(startTime, endTime);

        const result = await response.json();
        setBlogRecipeData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setblogrecipeLabels(chartLabels);
        setblogrecipeValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "brand_category"; // Table parameter

        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );


        const result = await response.json();
        setBrandcategoryRecipeData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setbrandcategoryrecipeLabels(chartLabels);
        setbrandcategoryrecipeValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "schedule_notification"; // Table parameter

        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );


        const result = await response.json();
        setSchedulenotificationRecipeData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setschedulenotificationrecipeLabels(chartLabels);
        setschedulenotificationrecipeValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "blog_statistics"; // Table parameter

        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );


        const result = await response.json();
        setBlogstatisticsRecipeData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setblogstatisticsrecipeLabels(chartLabels);
        setblogstatisticsrecipeValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const startTime = 0; // Start timestamp
        // const endTime = Math.floor(new Date().getTime()/1000.0); // End timestamp
        const { startTime, endTime } = getTimestamps();
        const table = "schedule_notification_statistics"; // Table parameter

        const response = await fetch(
          `https://api.barsys.ai/barsysanalytics/public/api/adminDahboardApiData?startTime=${startTime}&endTime=${endTime}&table=${table}`
        );


        const result = await response.json();
        setNotificationRecipeData(result); // Set fetched data to state

        const chartLabels = result.dateswise.map((item) => item.label);
        const chartValues = result.dateswise.map((item) => item.value);

        setnotificationrecipeLabels(chartLabels);
        setnotificationrecipeValues(chartValues);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [searchTriggered]);

  var themeColor = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme")
    .trim();
  var themeColorRgb = getComputedStyle(document.body)
    .getPropertyValue("--bs-theme-rgb")
    .trim();
  var bodyBg = getComputedStyle(document.body)
    .getPropertyValue("--bs-body-bg")
    .trim();

  const handleSearch = () => {
    setSearchTriggered((prev) => !prev); // Toggle searchTriggered to re-trigger fetching
  };

  return (
    <div className="row">
      <div
        className="col-xl-12 mb-4 d-flex flex-column flex-md-row justify-content-center align-items-center"
        style={{ textAlign: "center", zIndex: "100" }}
      >
        <div className="col-md-3 mb-3 mb-md-0 me-md-4">
          <label className="me-2">From:</label>
          <DatePicker
            selected={fromDate}
            onChange={(date) => setFromDate(date)}
            className="form-control"
            placeholderText="mm/dd/yyyy"
          />
        </div>
        <div className="col-md-3 mb-3 mb-md-0">
          <label className="me-2">To:</label>
          <DatePicker
            selected={toDate}
            onChange={(date) => setToDate(date)}
            className="form-control"
            placeholderText="mm/dd/yyyy"
          />
        </div>
        <button onClick={handleSearch} className="btn btn-secondary">
          <i className="fas fa-search"></i>
        </button>
      </div>

      {/* User- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Users"}</h6>
              <ModalComponent
                cardBodyContent={
                  <UserExpand
                    data={data}
                    labels={labels}
                    values={values}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {data && data.total !== undefined && data.total !== null ? (
                    data.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}
                </h3>
              </div>

              <div className="col-7 mt-3"></div>
            </div>
          </CardBody>
        </Card>
      </div>
      {/* User- Ends*/}

      {/* Curated Recipe- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Curated Recipe"}</h6>
              <ModalComponent
                cardBodyContent={
                  <CuratedRecipeExpand
                    data={curatedrecipedata}
                    labels={curatedrecipelabels}
                    values={curatedrecipevalues}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {curatedrecipedata &&
                  curatedrecipedata.total !== undefined &&
                  curatedrecipedata.total !== null ? (
                    curatedrecipedata.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}
                </h3>
              </div>
              {/* <div className="col-7 mt-3 mb-3"> */}
              <div className="col-7 mt-3">
                {/* {statsData &&
                        statsData.length > 0 &&
                        statsData
                          .filter((stat, index) => index === 0)
                          .map((stat, index) => (
                            <Chart
                              type={stat.chartType}
                              height={stat.chartHeight}
                              options={data}
                              series={data}
                              key={index}
                            />
                          ))} */}
              </div>
            </div>
            {/* <div className="small text-inverse text-opacity-50 text-truncate">
                  {data && data.userPreviuseWeek > 0 ? (
        data.userWeek !== data.userPreviuseWeek ? (
          <>
            <i
              className={`fas ${
                data.userWeek > data.userPreviuseWeek
                  ? "fa-angle-up"
                  : "fa-angle-down"
              }`}
            ></i>
            &nbsp;
            {Math.abs(
              ((data.userWeek - data.userPreviuseWeek) /
                data.userPreviuseWeek) *
                100
            ).toFixed(2)}{" "}
            %{" "}
            {data.userWeek > data.userPreviuseWeek
              ? "more"
              : "less"}{" "}
            than last week
          </>
        ) : (
          "No change from last week"
        )
      ) : (
        "Data unavailable"
      )}
                    
                    <br></br>
                    <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                    new visitors
                    <br></br>
                   
                  </div> */}
          </CardBody>
        </Card>
      </div>
      {/* Curated Recipe- Ends*/}

      {/* Custom Recipe- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Custom Recipe"}</h6>
              <ModalComponent
                cardBodyContent={
                  <CustomRecipeExpand
                    data={customrecipedata}
                    labels={customrecipelabels}
                    values={customrecipevalues}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {customrecipedata &&
                  customrecipedata.total !== undefined &&
                  customrecipedata.total !== null ? (
                    customrecipedata.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}

                  {/* 1.98 M */}
                </h3>
              </div>
              {/* <div className="col-7 mt-3 mb-3"> */}
              <div className="col-7 mt-3">
                {/* {statsData &&
                        statsData.length > 0 &&
                        statsData
                          .filter((stat, index) => index === 0)
                          .map((stat, index) => (
                            <Chart
                              type={stat.chartType}
                              height={stat.chartHeight}
                              options={data}
                              series={data}
                              key={index}
                            />
                          ))} */}
              </div>
            </div>
            {/* <div className="small text-inverse text-opacity-50 text-truncate">
                  {data && data.userPreviuseWeek > 0 ? (
        data.userWeek !== data.userPreviuseWeek ? (
          <>
            <i
              className={`fas ${
                data.userWeek > data.userPreviuseWeek
                  ? "fa-angle-up"
                  : "fa-angle-down"
              }`}
            ></i>
            &nbsp;
            {Math.abs(
              ((data.userWeek - data.userPreviuseWeek) /
                data.userPreviuseWeek) *
                100
            ).toFixed(2)}{" "}
            %{" "}
            {data.userWeek > data.userPreviuseWeek
              ? "more"
              : "less"}{" "}
            than last week
          </>
        ) : (
          "No change from last week"
        )
      ) : (
        "Data unavailable"
      )}
                    
                    <br></br>
                    <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                    new visitors
                    <br></br>
                   
                  </div> */}
          </CardBody>
        </Card>
      </div>
      {/* Custom Recipe- Ends*/}

      {/* Total Make- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Total Make"}</h6>
              <ModalComponent
                cardBodyContent={
                  <MakeRecipeExpand
                    data={makerecipedata}
                    labels={makerecipelabels}
                    values={makerecipevalues}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {makerecipedata &&
                  makerecipedata.total !== undefined &&
                  makerecipedata.total !== null ? (
                    makerecipedata.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}

                  {/* 1.98 M */}
                </h3>
              </div>
              {/* <div className="col-7 mt-3 mb-3"> */}
              <div className="col-7 mt-3">
                {/* {statsData &&
                        statsData.length > 0 &&
                        statsData
                          .filter((stat, index) => index === 0)
                          .map((stat, index) => (
                            <Chart
                              type={stat.chartType}
                              height={stat.chartHeight}
                              options={data}
                              series={data}
                              key={index}
                            />
                          ))} */}
              </div>
            </div>
            {/* <div className="small text-inverse text-opacity-50 text-truncate">
                  {data && data.userPreviuseWeek > 0 ? (
        data.userWeek !== data.userPreviuseWeek ? (
          <>
            <i
              className={`fas ${
                data.userWeek > data.userPreviuseWeek
                  ? "fa-angle-up"
                  : "fa-angle-down"
              }`}
            ></i>
            &nbsp;
            {Math.abs(
              ((data.userWeek - data.userPreviuseWeek) /
                data.userPreviuseWeek) *
                100
            ).toFixed(2)}{" "}
            %{" "}
            {data.userWeek > data.userPreviuseWeek
              ? "more"
              : "less"}{" "}
            than last week
          </>
        ) : (
          "No change from last week"
        )
      ) : (
        "Data unavailable"
      )}
                    
                    <br></br>
                    <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                    new visitors
                    <br></br>
                   
                  </div> */}
          </CardBody>
        </Card>
      </div>
      {/* Total Make- Ends*/}

      {/* Total Like- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Total Like"}</h6>
              <ModalComponent
                cardBodyContent={
                  <LikeRecipeExpand
                    data={likerecipedata}
                    labels={likerecipelabels}
                    values={likerecipevalues}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {likerecipedata &&
                  likerecipedata.total !== undefined &&
                  likerecipedata.total !== null ? (
                    likerecipedata.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}

                  {/* 1.98 M */}
                </h3>
              </div>
              {/* <div className="col-7 mt-3 mb-3"> */}
              <div className="col-7 mt-3">
                {/* {statsData &&
                        statsData.length > 0 &&
                        statsData
                          .filter((stat, index) => index === 0)
                          .map((stat, index) => (
                            <Chart
                              type={stat.chartType}
                              height={stat.chartHeight}
                              options={data}
                              series={data}
                              key={index}
                            />
                          ))} */}
              </div>
            </div>
            {/* <div className="small text-inverse text-opacity-50 text-truncate">
                  {data && data.userPreviuseWeek > 0 ? (
        data.userWeek !== data.userPreviuseWeek ? (
          <>
            <i
              className={`fas ${
                data.userWeek > data.userPreviuseWeek
                  ? "fa-angle-up"
                  : "fa-angle-down"
              }`}
            ></i>
            &nbsp;
            {Math.abs(
              ((data.userWeek - data.userPreviuseWeek) /
                data.userPreviuseWeek) *
                100
            ).toFixed(2)}{" "}
            %{" "}
            {data.userWeek > data.userPreviuseWeek
              ? "more"
              : "less"}{" "}
            than last week
          </>
        ) : (
          "No change from last week"
        )
      ) : (
        "Data unavailable"
      )}
                    
                    <br></br>
                    <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                    new visitors
                    <br></br>
                   
                  </div> */}
          </CardBody>
        </Card>
      </div>
      {/* Total Like- Ends*/}

      {/* Total View- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Total View"}</h6>
              <ModalComponent
                cardBodyContent={
                  <ViewRecipeExpand
                    data={viewrecipedata}
                    labels={viewrecipelabels}
                    values={viewrecipevalues}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {viewrecipedata &&
                  viewrecipedata.total !== undefined &&
                  viewrecipedata.total !== null ? (
                    viewrecipedata.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}

                  {/* 1.98 M */}
                </h3>
              </div>
              {/* <div className="col-7 mt-3 mb-3"> */}
              <div className="col-7 mt-3">
                {/* {statsData &&
                        statsData.length > 0 &&
                        statsData
                          .filter((stat, index) => index === 0)
                          .map((stat, index) => (
                            <Chart
                              type={stat.chartType}
                              height={stat.chartHeight}
                              options={data}
                              series={data}
                              key={index}
                            />
                          ))} */}
              </div>
            </div>
            {/* <div className="small text-inverse text-opacity-50 text-truncate">
                  {data && data.userPreviuseWeek > 0 ? (
        data.userWeek !== data.userPreviuseWeek ? (
          <>
            <i
              className={`fas ${
                data.userWeek > data.userPreviuseWeek
                  ? "fa-angle-up"
                  : "fa-angle-down"
              }`}
            ></i>
            &nbsp;
            {Math.abs(
              ((data.userWeek - data.userPreviuseWeek) /
                data.userPreviuseWeek) *
                100
            ).toFixed(2)}{" "}
            %{" "}
            {data.userWeek > data.userPreviuseWeek
              ? "more"
              : "less"}{" "}
            than last week
          </>
        ) : (
          "No change from last week"
        )
      ) : (
        "Data unavailable"
      )}
                    
                    <br></br>
                    <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                    new visitors
                    <br></br>
                    
                  </div> */}
          </CardBody>
        </Card>
      </div>
      {/* Total View - Ends*/}

      {/* Brand- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Brand"}</h6>
              <ModalComponent
                cardBodyContent={
                  <BrandRecipeExpand
                    data={brandrecipedata}
                    labels={brandrecipelabels}
                    values={brandrecipevalues}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {brandrecipedata &&
                  brandrecipedata.total !== undefined &&
                  brandrecipedata.total !== null ? (
                    brandrecipedata.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}

                  {/* 1.98 M */}
                </h3>
              </div>
              {/* <div className="col-7 mt-3 mb-3"> */}
              <div className="col-7 mt-3">
                {/* {statsData &&
                        statsData.length > 0 &&
                        statsData
                          .filter((stat, index) => index === 0)
                          .map((stat, index) => (
                            <Chart
                              type={stat.chartType}
                              height={stat.chartHeight}
                              options={data}
                              series={data}
                              key={index}
                            />
                          ))} */}
              </div>
            </div>
            {/* <div className="small text-inverse text-opacity-50 text-truncate">
                  {data && data.userPreviuseWeek > 0 ? (
        data.userWeek !== data.userPreviuseWeek ? (
          <>
            <i
              className={`fas ${
                data.userWeek > data.userPreviuseWeek
                  ? "fa-angle-up"
                  : "fa-angle-down"
              }`}
            ></i>
            &nbsp;
            {Math.abs(
              ((data.userWeek - data.userPreviuseWeek) /
                data.userPreviuseWeek) *
                100
            ).toFixed(2)}{" "}
            %{" "}
            {data.userWeek > data.userPreviuseWeek
              ? "more"
              : "less"}{" "}
            than last week
          </>
        ) : (
          "No change from last week"
        )
      ) : (
        "Data unavailable"
      )}
                    
                    <br></br>
                    <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                    new visitors
                    <br></br>
                  
                  </div> */}
          </CardBody>
        </Card>
      </div>
      {/* Brand- Ends*/}

      {/* Brand Category- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Brand Category"}</h6>
              <ModalComponent
                cardBodyContent={
                  <BrandcategoryRecipeExpand
                    data={brandcategoryrecipedata}
                    labels={brandcategoryrecipelabels}
                    values={brandcategoryrecipevalues}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {brandcategoryrecipedata &&
                  brandcategoryrecipedata.total !== undefined &&
                  brandcategoryrecipedata.total !== null ? (
                    brandcategoryrecipedata.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}

                  {/* 1.98 M */}
                </h3>
              </div>
              {/* <div className="col-7 mt-3 mb-3"> */}
              <div className="col-7 mt-3">
                {/* {statsData &&
                        statsData.length > 0 &&
                        statsData
                          .filter((stat, index) => index === 0)
                          .map((stat, index) => (
                            <Chart
                              type={stat.chartType}
                              height={stat.chartHeight}
                              options={data}
                              series={data}
                              key={index}
                            />
                          ))} */}
              </div>
            </div>
            {/* <div className="small text-inverse text-opacity-50 text-truncate">
                  {data && data.userPreviuseWeek > 0 ? (
        data.userWeek !== data.userPreviuseWeek ? (
          <>
            <i
              className={`fas ${
                data.userWeek > data.userPreviuseWeek
                  ? "fa-angle-up"
                  : "fa-angle-down"
              }`}
            ></i>
            &nbsp;
            {Math.abs(
              ((data.userWeek - data.userPreviuseWeek) /
                data.userPreviuseWeek) *
                100
            ).toFixed(2)}{" "}
            %{" "}
            {data.userWeek > data.userPreviuseWeek
              ? "more"
              : "less"}{" "}
            than last week
          </>
        ) : (
          "No change from last week"
        )
      ) : (
        "Data unavailable"
      )}
                    
                    <br></br>
                    <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                    new visitors
                    <br></br>
                  
                  </div> */}
          </CardBody>
        </Card>
      </div>
      {/* Brand Category- Ends*/}

      {/* Blog/News- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Blog/News"}</h6>
              <ModalComponent
                cardBodyContent={
                  <BlogRecipeExpand
                    data={blogrecipedata}
                    labels={blogrecipelabels}
                    values={blogrecipevalues}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {blogrecipedata &&
                  blogrecipedata.total !== undefined &&
                  blogrecipedata.total !== null ? (
                    blogrecipedata.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}
                </h3>
              </div>
              {/* <div className="col-7 mt-3 mb-3"> */}
              <div className="col-7 mt-3">
                {/* {statsData &&
                        statsData.length > 0 &&
                        statsData
                          .filter((stat, index) => index === 0)
                          .map((stat, index) => (
                            <Chart
                              type={stat.chartType}
                              height={stat.chartHeight}
                              options={data}
                              series={data}
                              key={index}
                            />
                          ))} */}
              </div>
            </div>
            {/* <div className="small text-inverse text-opacity-50 text-truncate">
                  {data && data.userPreviuseWeek > 0 ? (
        data.userWeek !== data.userPreviuseWeek ? (
          <>
            <i
              className={`fas ${
                data.userWeek > data.userPreviuseWeek
                  ? "fa-angle-up"
                  : "fa-angle-down"
              }`}
            ></i>
            &nbsp;
            {Math.abs(
              ((data.userWeek - data.userPreviuseWeek) /
                data.userPreviuseWeek) *
                100
            ).toFixed(2)}{" "}
            %{" "}
            {data.userWeek > data.userPreviuseWeek
              ? "more"
              : "less"}{" "}
            than last week
          </>
        ) : (
          "No change from last week"
        )
      ) : (
        "Data unavailable"
      )}
                    
                    <br></br>
                    <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                    new visitors
                    <br></br>
                   
                  </div> */}
          </CardBody>
        </Card>
      </div>
      {/* User- Ends*/}

      {/* Schedule Notification- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Schedule Notification"}</h6>
              <ModalComponent
                cardBodyContent={
                  <ScheduleRecipeExpand
                    data={schedulenotificationrecipedata}
                    labels={schedulenotificationrecipelabels}
                    values={schedulenotificationrecipevalues}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {schedulenotificationrecipedata &&
                    schedulenotificationrecipedata.total !== undefined &&
                    schedulenotificationrecipedata.total !== null ? (
                      schedulenotificationrecipedata.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}

                  {/* 1.98 M */}
                </h3>
              </div>
              {/* <div className="col-7 mt-3 mb-3"> */}
              <div className="col-7 mt-3">
                {/* {statsData &&
                        statsData.length > 0 &&
                        statsData
                          .filter((stat, index) => index === 0)
                          .map((stat, index) => (
                            <Chart
                              type={stat.chartType}
                              height={stat.chartHeight}
                              options={data}
                              series={data}
                              key={index}
                            />
                          ))} */}
              </div>
            </div>
            {/* <div className="small text-inverse text-opacity-50 text-truncate">
                  {data && data.userPreviuseWeek > 0 ? (
        data.userWeek !== data.userPreviuseWeek ? (
          <>
            <i
              className={`fas ${
                data.userWeek > data.userPreviuseWeek
                  ? "fa-angle-up"
                  : "fa-angle-down"
              }`}
            ></i>
            &nbsp;
            {Math.abs(
              ((data.userWeek - data.userPreviuseWeek) /
                data.userPreviuseWeek) *
                100
            ).toFixed(2)}{" "}
            %{" "}
            {data.userWeek > data.userPreviuseWeek
              ? "more"
              : "less"}{" "}
            than last week
          </>
        ) : (
          "No change from last week"
        )
      ) : (
        "Data unavailable"
      )}
                    
                    <br></br>
                    <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                    new visitors
                    <br></br>
                  
                  </div> */}
          </CardBody>
        </Card>
      </div>
      {/* Schedule Notification- Ends*/}

      {/* Blog/News View Statistic- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Blog/News View Statistic"}</h6>
              <ModalComponent
                cardBodyContent={
                  <BlognewsRecipeExpand
                    data={blogstatisticsrecipedata}
                    labels={blogstatisticsrecipelabels}
                    values={blogstatisticsrecipevalues}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {blogstatisticsrecipedata &&
                    blogstatisticsrecipedata.total !== undefined &&
                    blogstatisticsrecipedata.total !== null ? (
                      blogstatisticsrecipedata.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}

                  {/* 1.98 M */}
                </h3>
              </div>
              {/* <div className="col-7 mt-3 mb-3"> */}
              <div className="col-7 mt-3">
                {/* {statsData &&
                        statsData.length > 0 &&
                        statsData
                          .filter((stat, index) => index === 0)
                          .map((stat, index) => (
                            <Chart
                              type={stat.chartType}
                              height={stat.chartHeight}
                              options={data}
                              series={data}
                              key={index}
                            />
                          ))} */}
              </div>
            </div>
            {/* <div className="small text-inverse text-opacity-50 text-truncate">
                  {data && data.userPreviuseWeek > 0 ? (
        data.userWeek !== data.userPreviuseWeek ? (
          <>
            <i
              className={`fas ${
                data.userWeek > data.userPreviuseWeek
                  ? "fa-angle-up"
                  : "fa-angle-down"
              }`}
            ></i>
            &nbsp;
            {Math.abs(
              ((data.userWeek - data.userPreviuseWeek) /
                data.userPreviuseWeek) *
                100
            ).toFixed(2)}{" "}
            %{" "}
            {data.userWeek > data.userPreviuseWeek
              ? "more"
              : "less"}{" "}
            than last week
          </>
        ) : (
          "No change from last week"
        )
      ) : (
        "Data unavailable"
      )}
                    
                    <br></br>
                    <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                    new visitors
                    <br></br>
                  
                  </div> */}
          </CardBody>
        </Card>
      </div>
      {/* User- Ends*/}

      {/* Notification View Statistic- Start*/}
      <div className="col-xl-4 col-lg-6">
        <Card className="mb-3">
          <CardBody>
            <div className="d-flex fw-bold small">
              <h6 className="flex-grow-1">{"Notification View Statistic"}</h6>
              <ModalComponent
                cardBodyContent={
                  <NotificationRecipeExpand
                    data={notificationrecipedata}
                    labels={notificationrecipelabels}
                    values={notificationrecipevalues}
                    themeColor={themeColor}
                    themeColorRgb={themeColorRgb}
                    bodyBg={bodyBg}
                  />
                }
              />
            </div>
            <div className="row align-items-center">
              <div className="col-5 mt-3 mb-3">
                <h3 className="mb-0">
                  {notificationrecipedata &&
                    notificationrecipedata.total !== undefined &&
                    notificationrecipedata.total !== null ? (
                      notificationrecipedata.total
                  ) : (
                    <div className="spinner-border text-success"></div>
                  )}
                </h3>
              </div>
              {/* <div className="col-7 mt-3 mb-3"> */}
              <div className="col-7 mt-3">
                {/* {statsData &&
                        statsData.length > 0 &&
                        statsData
                          .filter((stat, index) => index === 0)
                          .map((stat, index) => (
                            <Chart
                              type={stat.chartType}
                              height={stat.chartHeight}
                              options={data}
                              series={data}
                              key={index}
                            />
                          ))} */}
              </div>
            </div>
            {/* <div className="small text-inverse text-opacity-50 text-truncate">
                  {data && data.userPreviuseWeek > 0 ? (
        data.userWeek !== data.userPreviuseWeek ? (
          <>
            <i
              className={`fas ${
                data.userWeek > data.userPreviuseWeek
                  ? "fa-angle-up"
                  : "fa-angle-down"
              }`}
            ></i>
            &nbsp;
            {Math.abs(
              ((data.userWeek - data.userPreviuseWeek) /
                data.userPreviuseWeek) *
                100
            ).toFixed(2)}{" "}
            %{" "}
            {data.userWeek > data.userPreviuseWeek
              ? "more"
              : "less"}{" "}
            than last week
          </>
        ) : (
          "No change from last week"
        )
      ) : (
        "Data unavailable"
      )}
                    
                    <br></br>
                    <i className="fas fa-user"></i> {data && data.userlastWeekUser}{" "}
                    new visitors
                    <br></br>
                   
                  </div> */}
          </CardBody>
        </Card>
      </div>
      {/* User- Ends*/}
    </div>
  );
};

export default Dashboardadmindata;
