const Menu = [
	{ is_header: true, title: 'Navigation' },
	{ path: '/dashboard', icon: 'bi bi-cpu', title: 'Dashboard' },
	{ path: '/user', icon: 'bi bi-person-fill', title: 'Users' },
	{ path: '/hardware', icon: 'bi bi-motherboard-fill', title: 'Hardware' },
	{ path: '/recipes', icon: 'fas fa-glass-martini', title: 'Recipes' },
	{ path: '/allrecipes', icon: 'fas fa-glass-martini', title: 'All Recipes' },
	{ path: '/ingredient', icon: 'bi bi-menu-button-wide-fill', title: 'Ingredients' },
	{ path: '/content', icon: 'bi bi-body-text', title: 'Content' },
	{ path: '/partners', icon: 'fas fa-handshake', title: 'Partners' },
	{ is_divider: true },
	{ path: '/googleanalytics', icon: 'fab fa-google', title: 'Barsys Analytics' ,
	children: [
		// { path: '/googleanalytics/home', icon: 'fas fa-home', title: 'Home' }, 
		{ path: '/googleanalytics/home',icon: 'fas fa-chart-line', title: 'Home' },
		{ path: '/googleanalytics/report',icon: 'fas fa-chart-line', title: 'Report' },
		{ path: '/googleanalytics/realtime',icon: 'fas fa-chart-line', title: 'Realtime' },
	]
},
{ path: '/firebaseanalytics', icon: 'fab fa-google', title: 'Firebase Analytics' ,
children: [
	// { path: '/googleanalytics/home', icon: 'fas fa-home', title: 'Home' }, 
	{ path: '/firebaseanalytics/home',icon: 'fas fa-chart-line', title: 'Home' },
	{ path: '/firebaseanalytics/report',icon: 'fas fa-chart-line', title: 'Report' },
	{ path: '/firebaseanalytics/realtime',icon: 'fas fa-chart-line', title: 'Realtime' },
	// { path: '/firebaseanalytics/appversion',icon: 'fas fa-chart-line', title: "App Version's Data" },
]
},
// { path: '/deviceinfo', icon: 'fas fa-cogs', title: 'Device Information' },
{ path: '/jsonformatter', icon: 'fas fa-cogs', title: 'JSON Formatter {Work in progress}' },
{ path: '/dashboardadmindata', icon: 'fas fa-cogs', title: 'Dashboard Admin' }
	// { path: '/analytics', icon: 'bi bi-bar-chart', title: 'Analytics' },
	// { path: '/email', icon: 'bi bi-envelope', title: 'Email',
	// 	children: [
	// 		{ path: '/email/inbox', title: 'Inbox' }, 
	// 		{ path: '/email/compose', title: 'Compose' }, 
	// 		{ path: '/email/detail', title: 'Detail' }
	// 	]
	// }, 
	// { is_divider: true }, 
	// { is_header: true, title: 'Components' }, 
	// { path: '/widgets', icon: 'bi bi-columns-gap', title: 'Widgets' }, 
	// { path: '/pos', icon: 'bi bi-bag-check', title: 'POS System',
	// 	highlight: true,
	// 	children: [
	// 		{ path: '/pos/customer-order', title: 'Customer Order' }, 
	// 		{ path: '/pos/kitchen-order', title: 'Kitchen Order' }, 
	// 		{ path: '/pos/counter-checkout', title: 'Counter Checkout' }, 
	// 		{ path: '/pos/table-booking', title: 'Table Booking' }, 
	// 		{ path: '/pos/menu-stock', title: 'Menu Stock' }
	// 	]
	// }, 
	// { path: '/ui', icon: 'fa fa-heart', title: 'UI Kits',
	// 	children: [
	// 		{ path: '/ui/bootstrap', title: 'Bootstrap' }, 
	// 		{ path: '/ui/buttons', title: 'Buttons' }, 
	// 		{ path: '/ui/card', title: 'Card' }, 
	// 		{ path: '/ui/icons', title: 'Icons' }, 
	// 		{ path: '/ui/modal-notifications', title: 'Modal & Notifications' }, 
	// 		{ path: '/ui/typography', title: 'Typography' }, 
	// 		{ path: '/ui/tabs-accordions', title: 'Tabs & Accordions' }
	// 	]
	// }, 
	// { path: '/form', icon: 'bi bi-pen', title: 'Forms',
	// 	children: [
	// 		{ path: '/form/elements', title: 'Form Elements' }, 
	// 		{ path: '/form/plugins', title: 'Form Plugins' }, 
	// 		{ path: '/form/wizards', title: 'Wizards' }
	// 	]
	// }, 
	// { path: '/table', icon: 'bi bi-grid-3x3', title: 'Tables',
	// 	children: [
	// 		{ path: '/table/elements', title: 'Table Elements' },
	// 		{ path: '/table/plugins', title: 'Table Plugins' }
	// 	]
	// }, 
	// { path: '/chart', icon: 'bi bi-pie-chart', title: 'Charts',
	// 	children: [
	// 		{ path: '/chart/chart-js', title: 'Chart.js' },
	// 		{ path: '/chart/chart-apex', title: 'Apexcharts.js' }
	// 	]
	// }, 
	// { path: '/map', icon: 'bi bi-compass', title: 'Map' }, 
	// { path: '/layout', icon: 'bi bi-layout-sidebar', title: 'Layout',
	// 	children: [
	// 		{ path: '/layout/starter-page', title: 'Starter Page' }, 
	// 		{ path: '/layout/fixed-footer', title: 'Fixed Footer' }, 
	// 		{ path: '/layout/full-height', title: 'Full Height' }, 
	// 		{ path: '/layout/full-width', title: 'Full Width' }, 
	// 		{ path: '/layout/boxed-layout', title: 'Boxed Layout' }, 
	// 		{ path: '/layout/collapsed-sidebar', title: 'Collapsed Sidebar' }, 
	// 		{ path: '/layout/top-nav', title: 'Top Nav' }, 
	// 		{ path: '/layout/mixed-nav', title: 'Mixed Nav' }, 
	// 		{ path: '/layout/mixed-nav-boxed-layout', title: 'Mixed Nav Boxed Layout' }
	// 	]
	// }, 
	// { path: '/pages', icon: 'bi bi-collection', title: 'Pages',
	// 	children: [
	// 		{ path: '/pages/scrum-board', title: 'Scrum Board' }, 
	// 		{ path: '/pages/products', title: 'Products' }, 
	// 		{ path: '/pages/product-details', title: 'Product Details' }, 
	// 		{ path: '/pages/orders', title: 'Orders' }, 
	// 		{ path: '/pages/order-details', title: 'Order Details' }, 
	// 		{ path: '/pages/gallery', title: 'Gallery' }, 
	// 		{ path: '/pages/search-results', title: 'Search Results' }, 
	// 		{ path: '/pages/coming-soon', title: 'Coming Soon Page' }, 
	// 		{ path: '/pages/error', title: 'Error Page' }, 
	// 		{ path: '/pages/login', title: 'Login' }, 
	// 		{ path: '/pages/register', title: 'Register' }, 
	// 		{ path: '/pages/messenger', title: 'Messenger' }, 
	// 		{ path: '/pages/data-management', title: 'Data Management' },
	// 		{ path: '/pages/file-manager', title: 'File Manager' }, 
	// 		{ path: '/pages/pricing', title: 'Pricing Page' }
	// 	]
	// }, 
	// { is_divider: true }, 
	// { is_header: true, title: 'Users' }, 
	// { path: '/profile', icon: 'bi bi-people', title: 'Profile' }, 
	// { path: '/calendar', icon: 'bi bi-calendar4', title: 'Calendar' }, 
	// { path: '/settings', icon: 'bi bi-gear', title: 'Settings' }, 
	// { path: '/helper', icon: 'bi bi-gem',	title: 'Helper' }
];

export default Menu;